<template>
  <div class="application">
    <!-- kv -->
    <section
      id="kv_content"
      :style="{ backgroundImage: `url( ${require('@/statics/img/banner/apply.webp')} )` }"
    >
      <div class="kv_title txt-white">
        <h1>APPLICATION</h1>
        <h2>入會申請</h2>
      </div>
    </section>

    <!-- current stage -->
    <section
      id="stage"
      :style="{ backgroundImage: `url( ${require('@/statics/img/index/kv_bg.jpg')} )` }"
    >
      <div class="w1300">

        <ApplicationStage />

      </div>
    </section>

    <main>
      <section id="application_verify">
        <div class="w1000">

          <!-- form area -->
          <div class="form_box">
            <form id="form_application_verify" @submit.prevent>
              <div class="form_description">
                <p>
                  <span id="user_name">{{ realname }}</span> 您好，<br><br>
                  <span class="txt-bold">
                    您的資料審核狀態為：
                    <span class="txt-red">{{ applyStatus }}</span>
                  </span><br>
                  <span v-show="applyMsg">備註：{{ applyMsg }}</span>
                </p>
              </div>
              <div class="form_interactive">
                <Upload
                  class="w100"
                  :class="{ disabled: applyStatus !== '請補件' }"
                  v-model="user.files.upload.file"
                  :item="inputFormat.attachmentUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                  :fileTitle.sync="user.files.upload.title"
                />
              </div>

              <div class="form_submit txt-center">
                <button
                  type="button"
                  class="btn"
                  :class="{ disabled: applyStatus !== '請補件' }"
                  @click="onSubmit"
                >送出</button>
              </div>
            </form>
          </div>

        </div>
      </section>
    </main>
  </div>
</template>

<script>
import '@/assets/scss/application.scss';

import ApplicationStage from '@/components/ApplicationStage.vue';
import Upload from '@/components/form/Upload.vue';
import { mapGetters, mapActions } from 'vuex';
import { userInfo, registerUpload } from '@/lib/http';
import _ from 'lodash';

export default {
  title: '入會申請',
  name: 'ApplicationVerify',
  data() {
    return {
      user: {
        uuid: '',
        files: {
          upload: {
            title: '',
            file: null,
          },
        },
      },
      inputFormat: {
        attachmentUpload: {
          label: '附檔上傳',
          reminder: '格式 jpg/png/pdf/zip。限制大小：4MB',
          accept: '.jpg,.png,.pdf,.zip',
          size: 4 * 1024 * 1024,
          required: true,
        },
      },
      realname: '註冊會員',
      applyStatus: '',
      applyMsg: '',
    };
  },
  created() {
    if (this.hasToken) {
      userInfo().then((result) => {
        this.realname = result.realname;
        this.user.uuid = result.uuid;
        const userApply = parseInt(_.get(result, 'apply', null), 10);
        const userLevel = parseInt(_.get(result, 'level', null), 10);
        const applyMsg = _.get(result, 'memo.apply', null);

        /** 非會員醫師才可進入此頁流程 */
        if (userLevel === 3) {
          /** 通過 */
          if (userApply === 2) {
            this.$router.push({ path: '/application/payment' });
          /** 拒絕 */
          } else if (userApply === 1) {
            this.applyMsg = applyMsg;
            this.applyStatus = '不通過';
            /**  */
            // if (applyMsg) {
            //   this.openModal({
            //     url: '/application/index',
            //     message: applyMsg,
            //   });
            // } else {
            //   this.openModal({
            //     url: '/application/index',
            //     message: '入會資格不符，詳情請洽學會',
            //   });
            // }
          /** 審核中 或 補件 */
          } else if (userApply === 0 || userApply === 9) {
            this.applyMsg = applyMsg;
            if (userApply === 0) {
              this.applyStatus = '審核中';
            } else if (userApply === 9) {
              this.applyStatus = '請補件';
            }
          } else {
            this.openModal({
              url: '/application/index',
              message: '請先至審核狀態查詢輸入身分證字號/居留證號碼',
            });
          }
        } else {
          this.openModal({
            url: '/application/index',
            message: '請先至審核狀態查詢輸入身分證字號/居留證號碼',
          });
        }
      });
    } else {
      this.openModal({
        url: '/application/index',
        message: '請先至審核狀態查詢輸入身分證字號/居留證號碼',
      });
    }
  },
  computed: {
    ...mapGetters([
      'hasToken',
    ]),
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        registerUpload(this.user).then((result) => {
          const responseUUID = _.get(result, 'data.result.uuid', null);
          if (responseUUID) {
            this.openModal({
              message: '上傳成功，請等待審核',
            });
          } else {
            this.openModal({
              message: '上傳失敗，請重新上傳',
            });
          }
        });
      } else {
        this.openModal({
          message: '請上傳附檔',
        });
      }
    },
  },
  components: {
    ApplicationStage,
    Upload,
  },
};
</script>
